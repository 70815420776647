import * as React from "react";
import "../styles/index.scss";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

// markup
const Datenschutzerklärung = () => {
  return (
    <>
      <Header />
      <Seo />
      <main className="data-protection" style={{ marginTop: "5rem" }}>
        <Container style={{ maxWidth: "650px" }}>
          <h2 className="section_title">Datenschutzerklärung</h2>
          <Row>
            <Col>
              <h3>Allgemeines</h3>
              Der Betreiber dieser Webseite erhebt, verarbeitet und nutzt
              personenbezogene Daten eines Nutzers ohne weitergehende
              Einwilligung nur soweit sie für die Vertragsbegründung und
              -abwicklung sowie zu Abrechnungszwecken erforderlich sind.
              <br />
              Personenbezogene Daten sind Einzelangaben über persönliche oder
              sachliche Verhältnisse einer bestimmten oder bestimmbaren
              natürlichen Person (Betroffener).
              <br />
              Das Erheben von Daten bedeutet insoweit das Beschaffen von Daten
              durch den Betroffenen selbst. Verarbeiten ist neben dem Speichern
              und Löschen auch das Verändern, das Sperren und die Übermittlung
              personenbezogener Daten. Nutzen ist jede Verwendung
              personenbezogener Daten, soweit es sich nicht um Verarbeitung
              handelt. Sofern notwendig, können Bestands- und Verkehrsdaten des
              Kunden an mit dem Betreiber der Webseite verbundene Unternehmen
              weitergegeben werden. Der Betreiber dieser Webseite ist nach
              Maßgabe der hierfür geltenden gesetzlichen Bestimmungen
              berechtigt, Auskunft an Strafverfolgungsbehörden und Gerichte zum
              Zwecke der Strafverfolgung zu erteilen. Der Betreiber dieser
              Webseite kann die Bestands- und Verkehrsdaten des Kunden zu
              Marktforschungszwecken auswerten bzw. durch Dritte auswerten
              lassen. Der Kunde kann dem durch Kontaktaufnahme mit dem
              Datenschutzbeauftragen widersprechen.
              <h3>Bestandsdaten</h3>
              Bestandsdaten sind personenbezogene Daten, die erforderlich sind,
              um das Vertragsverhältnis zwischen dem Betreiber dieser Webseite
              und dem Nutzer zu begründen oder zu ändern. Hierzu gehören unter
              anderem der Name und die Anschrift, Geburtsdatum, Telefon,
              E-Mail-Adresse und Zahlungsdaten des Nutzers.
              <br />
              Der Betreiber dieser Webseite wird Bestandsdaten nur dann an
              Dritte weiterleiten, sofern dies für die Erbringung der
              Dienstleistung erforderlich ist.
              <br />
              Der Betreiber dieser Webseite wird die Bestandsdaten für Zwecke
              der Werbung, Kundenberatung oder Marktforschung nur verarbeiten
              und nutzen, soweit dies erforderlich ist und der Nutzer
              eingewilligt hat. Die Einwilligung kann der Nutzer jederzeit
              schriftlich widerrufen.
              <br />
              Die Vorschrift in §29 BDSG erlaubt eine geschäftsmäßige Erhebung,
              Speicherung oder Veränderung personenbezogener Daten zum Zweck der
              Übermittlung insbesondere im Zusammenhang mit der Tätigkeit von
              Auskunfteien. Auskunfteien verknüpfen personenbezogene Daten, die
              aus verschiedenen Quellen stammen und übermitteln diese an Dritte.
              Der Betreiber dieser Webseite ist verpflichtet, solchen
              <h3>Verkehrsdaten</h3>
              Verkehrsdaten sind Daten, die bei der Erbringung eines
              Telekommunikationsdienstes erhoben, verarbeitet oder genutzt
              werden. Der Betreiber dieser Webseite bzw. ein Dritter, dessen
              sich der Betreiber dieser Webseite zur Erbringung der
              Telekommunikationsdienste bedient, erhebt Verkehrsdaten, um die
              Inanspruchnahme dieser Dienste zu ermöglichen und die Rechnung des
              jeweiligen Nutzers erstellen zu können. In der Regel werden dabei
              Datum und Uhrzeit sowie Zeitzone des Beginns und Endes der
              Nutzung, der Umfang in Bytes, die IP-Adresse und die Art des in
              Anspruch genommenen Teledienstes erfasst. Die vorgenannten Daten
              werden bis zu sechs Monate nach der Versendung der Rechnung
              gespeichert, es sei denn im Rahmen der vom Nutzer aktivierten
              Statistikfunktionen ist eine längere Speicherung vorgesehen.
              Soweit es die Abrechnung mit anderen Unternehmen oder mit anderen
              Diensteanbietern oder gesetzliche Vorschriften erfordern, darf der
              Betreiber dieser Webseite Verkehrsdaten verwenden, speichern und
              übermitteln.
              <h3>Auskunftsrecht</h3>
              Der Betreiber dieser Webseite teilt dem Nutzer auf Anfrage
              schriftlich unter Berücksichtigung der gesetzlichen Vorgaben mit,
              ob und welche persönlichen Daten über ihn gespeichert sind.
              Hierfür und für weitere Fragen zum Datenschutz steht Ihnen der
              Datenschutzbeauftragte zur Verfügung.
              <h3>Website-Tracking</h3>
              Diese Website verwendet teilweise so genannte Cookies. Diese
              dienen dazu, das Angebot nutzerfreundlicher, effektiver und
              sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
              Rechner abgelegt werden und die Ihr Browser speichert und die eine
              Analyse der Benutzung der Website durch Sie ermöglichen. Sie
              können die Speicherung dieses und der unten genannten Cookies
              durch eine entsprechende Einstellung Ihrer Browser-Software
              verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
              Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
              vollumfänglich werden nutzen können.
              <br />
              Diese Webseite benutzt Google Analytics, einen Webanalysedienst
              der Google Inc. („Google“). Google Analytics verwendet sog.
              „Cookies“. Die durch den Cookie erzeugten Informationen über Ihre
              Benutzung dieser Website werden in der Regel an einen Server von
              Google in den USA übertragen und dort gespeichert. Google wird
              diese Informationen benutzen, um Ihre Nutzung der Website
              auszuwerten, um Reports über die Websiteaktivitäten
              zusammenzustellen und um weitere mit der Websitenutzung und der
              Internetnutzung verbundene Dienstleistungen gegenüber dem
              Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics
              von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
              Daten von Google zusammengeführt.
              <br />
              Die Cookies werden darüber hinaus für die Remarketing Technologie
              der Google Inc. verwendet. Durch diese Technologie werden Nutzer,
              die diese Website bereits besucht und sich für das Angebot
              interessiert haben, durch zielgerichtete Werbung auf den Seiten
              des Google Partner Netzwerks erneut angesprochen. Sofern Nutzer
              zugestimmt haben, dass ihr Web- und App-Browserverlauf von Google
              mit ihrem Google-Konto verknüpft wird und Informationen aus ihrem
              Google-Konto zum Personalisieren von Anzeigen verwendet werden,
              erfolgt die Remarketing-Funktion geräteübergreifend. Zur
              Unterstützung dieser Funktion werden von Google Analytics Google-
              authentifzierte IDs dieser Nutzer erfasst. Diese personenbezogenen
              Daten werden vorübergehend mit den Google Analytics-Daten
              verknüpft, um Zielgruppen bilden zu können. Google-Nutzer können
              ihre Anzeigeneinstellungen unter „Mein Konto“ anpassen oder
              personalisierte Anzeigen deaktivieren.
              <br />
              Sie können darüber hinaus die Erfassung der durch das Cookie
              erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
              Ihrer gekürzten IP-Adresse) an Google sowie die Verarbeitung
              dieser Daten durch Google verhindern, indem sie das unter dem
              folgenden Link verfügbare Browser-Plugin herunterladen und
              installieren: [http://tools.google.com/dlpage/gaoptout?hl=de]
              <br />
              Darüber hinaus erhebt Google Analytics-Berichte zur Leistung nach
              demografischen Merkmalen und Interesse.
              <h3>Social Plugins</h3>
              Diese Website nutzt verschiedene Funktionen von den sozialen
              Netzwerken Facebook, Twitter und Google+. Standardmäßig werden bei
              dem Besuch unserer Website keine Daten an diese Dienste
              übertragen. Auf einigen Seiten kann nach dem Setzen eines Hakens
              beim entsprechenden Plugin bzw. beim Klick auf einen
              entsprechenden Link bei der jeweiligen Plattform eine Übertragung
              von Daten gemäß der nachfolgenden Bedingungen stattfinden, ohne
              dass die Funktion („Empfehlen“, „Tweet“, „+1“) selbst durchgeführt
              wird.
              <h3>YouTube</h3>
              Diese Webseite nutzt Plugins der von Google betriebenen Seite
              YouTube. Betreiber der Seiten ist die YouTube, LLC, 901 Cherry
              Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem
              YouTube-Plugin ausgestatteten Seiten besuchen, wird eine
              Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
              Youtube-Server mitgeteilt, welche unserer Seiten Sie besucht
              haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind
              ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
              persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem
              Sie sich aus Ihrem YouTube-Account ausloggen.
              <br />
              Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der
              Datenschutzerklärung von YouTube unter:
              https://www.google.de/intl/de/policies/privacy
              <h3>SoundCloud</h3>
              Auf unseren Seiten können Plugins des sozialen Netzwerks
              SoundCloud (SoundCloud Limited, 33 St James Square, London SW1Y
              4JS, UK) integriert sein. Die SoundCloud-Plugins erkennen Sie an
              dem SoundCloud-Logo auf den betroffenen Seiten.
              <br />
              Wenn Sie unsere Seiten besuchen, wird nach Aktivierung des Plugin
              eine direkte Verbindung zwischen Ihrem Browser und dem
              SoundCloud-Server hergestellt. SoundCloud erhält dadurch die
              Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht
              haben. Wenn Sie den „Like-Button“ oder „Share-Button“ anklicken
              während Sie in Ihrem SoundCloud-Account eingeloggt sind, können
              Sie die Inhalte unserer Seiten mit Ihrem SoundCloud-Profil
              verlinken und/oder teilen. Dadurch kann SoundCloud den Besuch
              unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
              hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
              der übermittelten Daten sowie deren Nutzung durch SoundCloud
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von SoundCloud unter
              https://soundcloud.com/pages/privacy
              <br />
              Wenn Sie nicht wünschen, dass Soundcloud den Besuch unserer Seiten
              Ihrem SoundCloud-Nutzerkonto zuordnen kann, loggen Sie sich bitte
              aus Ihrem SoundCloud-Benutzerkonto aus, bevor Sie Inhalte des
              SoundCloud-Plugins aktivieren.
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default Datenschutzerklärung;
